<template>
  <div v-if="isLoading">
    <div class="text-center text-danger custom-screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <h3 class="text-center mb-4">Filtros</h3>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Fecha:</strong></label>
          <div class="col-sm-2 pr-3">
            <date-picker
              class="col-sm-12 px-0"
              v-model="fecha"
              lang="es"
              format="DD/MM/YYYY"
              type="date"
              value-type="date"
            >
            </date-picker>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Usuario:</strong> </label>
          <div class="col-sm-3">
            <multiselect
              v-model="usersSelected"
              :options="users"
              :disabled="false"
              label="name" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Usuarios"
              :allow-empty="false"
              :searchable="true">
              <span slot="caret" v-if="!(usersSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect>
          </div>
        </div>
        <div align="center">
          <button class="btn btn-warning"
           type="button"
           @click="generarInforme">
            Consultar
          </button>
        </div>
      </div>
      <div class="col-sm-6 notify">
        <div class="bg-infolite p-4">
          <strong>Nota:</strong> El presente reporte solo refleja información de ingresos realizados en las ventas, no muestra información de Ajuste o Venta a Crédito.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';
/* eslint radix: ["error", "as-needed"] */

export default {
  name: 'ArqueoCaja',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      usersSelected: null,
      users: [],

      isLoading: true,
      fecha: new Date(),
      fechaFinal: new Date(),
      listaUsers: [],
    };
  },
  async created() {
    this.isLoading = true;
    await this.cargarDatosIniciales();
    this.isLoading = false;
  },
  methods: {
    async cargarDatosIniciales() {
      try {
        const RESPONSE = await axios.get('clinic/reporte/arqueo_caja/create');
        this.users = RESPONSE.data.data.users;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async generarInforme() {
      if (this.fecha === null) {
        util.showNotify('Elija una Fecha de Inicio.', 'warn');
        return;
      }
      if (this.fechaFinal === null) {
        util.showNotify('Elija una Fecha Final.', 'warn');
        return;
      }
      if (this.usersSelected === null) {
        util.showNotify('Elije un Usuario', 'warn');
        return;
      }
      const data = {
        fecha: moment(this.fecha).format('YYYY-MM-DD'),
        user_id: this.usersSelected.id,
      };
      try {
        const RESPONSE = await axios.get('clinic/reporte/arqueo_caja/print/excel', {
          params: data,
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'data:application/vnd.ms-excel' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        const A = document.createElement('a');
        A.href = DOWNLOAD_URL;
        A.download = 'Reporte Arqueo Caja.xlsx';
        document.body.appendChild(A);
        A.click();
        document.body.removeChild(A);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    disableYear(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    disableMonth(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
  },
};
</script>

<style scoped>
.notify {
  padding-top: 100px;
}
.bg-infolite {
  background-color: rgb(3, 159, 245, 0.2);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
