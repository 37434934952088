<template>
  <div>
    <div class="breadcrumb">
      <h1 class="mb-0"><strong>Reportes</strong></h1>
    </div>
    <div class="row">
      <div class="col-sm-12 mb-4">
        <div class="card col-sm-12 px-0">
          <div class="card-header">
              <button class="btn btn-skyblue mr-3">Dashboard</button>
              <button class="btn btn-success">Reportes</button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                <b-list-group>
                  <b-list-group-item
                    class="text-center th-custom-color"
                  >
                    <strong>Clinica</strong>
                  </b-list-group-item>
                  <b-list-group-item href="#"
                    :class="isInformeDelDia ? 'active-item': ''"
                    @click="componentShow = 'InformeDelDia'"
                  >
                    Venta del Día
                  </b-list-group-item>
                  <b-list-group-item href="#"
                    :class="isResumenVenta ? 'active-item': ''"
                    @click="componentShow = 'ResumenVenta'">
                    Resumen de Ingreso
                  </b-list-group-item>
                  <b-list-group-item href="#"
                    :class="isResumenVentaDetallado ? 'active-item': ''"
                    @click="componentShow = 'ResumenVentaDetallado'">
                    Resumen de Venta Detallado
                  </b-list-group-item>
                  <b-list-group-item href="#"
                    :class="isVentaTipoServicio ? 'active-item': ''"
                    @click="componentShow = 'VentaTipoServicio'">
                    Venta por Tipo de Servicio
                  </b-list-group-item>
                  <b-list-group-item href="#"
                      :class="isArqueoCajaClinica ? 'active-item': ''"
                      @click="componentShow = 'ArqueoCajaClinica'">
                      Arqueo de Caja - Clinica
                    </b-list-group-item>
                  <template v-if="isBloqueadoSeccionMedicoTercero === false && hasPermissionMedicoTercero">
                    <b-list-group-item
                      class="text-center th-custom-color"
                    >
                      <strong>Medico Tercero</strong>
                    </b-list-group-item>
                    <b-list-group-item href="#"
                      :class="isMedicoTerceros ? 'active-item': ''"
                      @click="componentShow = 'MedicoTerceros'">
                      Venta del Día
                    </b-list-group-item>
                    <b-list-group-item href="#"
                      v-if="isBloqueadoPagoMedicoTercero === false"
                      :class="isPagoPendienteMedico ? 'active-item': ''"
                      @click="componentShow = 'PagoPendienteMedico'">
                      Pago Medicos
                    </b-list-group-item>
                    <b-list-group-item href="#"
                      :class="isFacturaMedico ? 'active-item': ''"
                      @click="componentShow = 'FacturaMedico'">
                      Facturas de Medicos
                    </b-list-group-item>
                    <b-list-group-item href="#"
                      :class="isEfectivoMedico ? 'active-item': ''"
                      @click="componentShow = 'EfectivoMedico'">
                      Efectivo por Medico
                    </b-list-group-item>
                    <b-list-group-item href="#"
                      :class="isArqueoCajaMedico ? 'active-item': ''"
                      @click="componentShow = 'ArqueoCajaMedico'">
                      Arqueo de Caja - Medico
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </div>
              <div class="col-sm-9">
                <InformeDelDia v-if="isInformeDelDia"/>
                <MedicoTerceros v-if="isMedicoTerceros"/>
                <PagoPendienteMedico v-if="isPagoPendienteMedico"/>
                <FacturaMedico v-if="isFacturaMedico"/>
                <ResumenVenta v-if="isResumenVenta"/>
                <ResumenVentaDetallado v-if="isResumenVentaDetallado"/>
                <VentaTipoServicio v-if="isVentaTipoServicio"/>
                <EfectivoMedico v-if="isEfectivoMedico"/>
                <ArqueoCajaMedico v-if="isArqueoCajaMedico"/>
                <ArqueoCajaClinica v-if="isArqueoCajaClinica"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InformeDelDia from '../components/TheInformeDelDia.vue';
import MedicoTerceros from '../components/TheMedicoTerceros.vue';
import PagoPendienteMedico from '../components/ThePagoPendienteMedico.vue';
import FacturaMedico from '../components/TheFacturaMedico.vue';
import ResumenVenta from '../components/TheResumenVenta.vue';
import ResumenVentaDetallado from '../components/TheResumenVentaDetallado.vue';
import VentaTipoServicio from '../components/TheVentaTipoServicio.vue';
import EfectivoMedico from '../components/TheEfectivoMedico.vue';
import ArqueoCajaMedico from '../components/TheArqueoCaja.vue';
import ArqueoCajaClinica from '../components/TheArqueoCajaClinica.vue';

export default {
  name: 'index',
  components: {
    InformeDelDia,
    MedicoTerceros,
    PagoPendienteMedico,
    FacturaMedico,
    ResumenVenta,
    ResumenVentaDetallado,
    VentaTipoServicio,
    EfectivoMedico,
    ArqueoCajaMedico,
    ArqueoCajaClinica,
  },
  data() {
    return {
      componentShow: 'InformeDelDia',
    };
  },
  computed: {
    ...mapGetters('main', [
      'isBloqueadoPagoMedicoTercero',
      'isBloqueadoSeccionMedicoTercero',
      'hasPermissionMedicoTercero',
    ]),
    isInformeDelDia() {
      return (this.componentShow === 'InformeDelDia');
    },
    isMedicoTerceros() {
      return (this.componentShow === 'MedicoTerceros');
    },
    isPagoPendienteMedico() {
      return (this.componentShow === 'PagoPendienteMedico');
    },
    isFacturaMedico() {
      return (this.componentShow === 'FacturaMedico');
    },
    isResumenVenta() {
      return (this.componentShow === 'ResumenVenta');
    },
    isResumenVentaDetallado() {
      return (this.componentShow === 'ResumenVentaDetallado');
    },
    isVentaTipoServicio() {
      return (this.componentShow === 'VentaTipoServicio');
    },
    isEfectivoMedico() {
      return (this.componentShow === 'EfectivoMedico');
    },
    isArqueoCajaMedico() {
      return (this.componentShow === 'ArqueoCajaMedico');
    },
    isArqueoCajaClinica() {
      return (this.componentShow === 'ArqueoCajaClinica');
    },
  },
};
</script>

<style scoped>
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.active-item{
  background-color: #bbb;
  border-color: #bbb;
  font-weight: bold;
}
</style>
