<template>
  <div v-if="isLoading">
    <div class="text-center text-danger custom-screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <h3 class="text-center mb-4">Filtros</h3>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Fecha Inicio:</strong></label>
          <div class="col-sm-2 pr-3">
            <date-picker
              class="col-sm-12 px-0"
              v-model="fechaInicio"
              lang="es"
              format="DD/MM/YYYY"
              type="date"
              value-type="date"
            >
            </date-picker>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Fecha Final:</strong></label>
          <div class="col-sm-2 pr-3">
            <date-picker
              class="col-sm-12 px-0"
              v-model="fechaFinal"
              lang="es"
              format="DD/MM/YYYY"
              type="date"
              value-type="date"
            >
            </date-picker>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Moneda:</strong></label>
          <div class="col-sm-3 pr-5">
            <multiselect
              v-model="monedaSelected"
              :options="monedas"
              label="name" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Moneda"
              :allow-empty="false"
              :show-labels="false">
              <span slot="caret" v-if="!(monedaSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect>
          </div>
        </div>
        <div align="center">
          <button
            class="btn btn-warning"
            type="button"
            @click="generarInforme">
            Consultar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'ResumenVentaDetallado',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      isLoading: true,
      fechaInicio: new Date(),
      fechaFinal: new Date(),
      monedaSelected: null,
      monedas: [],
    };
  },
  async created() {
    await this.cargarDatosIniciales();
    this.isLoading = false;
  },
  methods: {
    async cargarDatosIniciales() {
      try {
        const RESPONSE = await axios.get('/clinic/reporte/resumen_venta_detallado/create');
        this.monedas = RESPONSE.data.data.monedas;
        this.monedaSelected = this.monedas.find((item) => item.is_principal === 1);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    generarInforme() {
      if (this.fechaInicio === null) {
        util.showNotify('Elija una Fecha de Inicio.', 'warn');
        return;
      }
      if (this.fechaFinal === null) {
        util.showNotify('Elija una Fecha Final.', 'warn');
        return;
      }
      if (this.monedaSelected === null) {
        return;
      }
      const data = {
        fechaInicio: moment(this.fechaInicio).format('YYYY-MM-DD'),
        fechaFinal: moment(this.fechaFinal).format('YYYY-MM-DD'),
        mid: this.monedaSelected.id,
      };
      const hash = btoa(JSON.stringify(data));
      window.open(`/reportes/resumen-venta-detallado/${hash}`);
    },
  },
};
</script>

<style scoped>
.custom-screen-center {
  transform: translateY(16vh);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
