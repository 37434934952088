<template>
  <div>
    <h3 class="text-center mb-4">Filtros</h3>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Gestión:</strong></label>
          <div class="col-sm-3">
            <div class="row">
              <date-picker
                v-model="yearInput"
                :disabled-date="disableYear"
                class="col-sm-6"
                lang="es"
                format="YYYY"
                type="year"
                placeholder="Año"
                width="100%">
              </date-picker>
              <date-picker
                v-model="monthInput"
                :disabled-date="disableMonth"
                class="col-sm-6"
                lang="es"
                format="MM"
                type="month"
                placeholder="Mes"
                width="100%">
              </date-picker>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Medico:</strong> </label>
          <div class="col-sm-3">
            <multiselect
              v-model="medicoSelected"
              :options="medicos"
              :disabled="false"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Medicos"
              :allow-empty="false"
              :searchable="true">
              <span slot="caret" v-if="!(medicoSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Tipo:</strong> </label>
          <div class="col-sm-3">
            <multiselect
              v-model="tipoSelected"
              :options="tipos"
              :disabled="false"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Tipos"
              :allow-empty="false"
              :searchable="true">
              <span slot="caret" v-if="!(tipoSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect>
          </div>
        </div>
        <div align="center">
          <button class="btn btn-warning"
           type="button"
           @click="generarInforme">
            Consultar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'FacturaMedico',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      medicoSelected: null,
      medicos: [],
      tipoSelected: null,
      tipos: [],
      yearInput: null,
      monthInput: null,
    };
  },
  created() {
    this.cargarDatosIniciales();
  },
  methods: {
    async cargarDatosIniciales() {
      try {
        const RESPONSE = await axios.get('clinic/reporte/impuesto/create');
        this.medicos = RESPONSE.data.data.medicos;
        this.tipos = RESPONSE.data.data.tipos;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async generarInforme() {
      if (this.yearInput === null) {
        util.showNotify('Elije un Año', 'warn');
        return;
      }
      if (this.monthInput === null) {
        util.showNotify('Elije un Mes', 'warn');
        return;
      }
      if (this.tipoSelected === null) {
        util.showNotify('Elije un tipo', 'warn');
        return;
      }
      if (this.medicoSelected === null) {
        util.showNotify('Elije un medico', 'warn');
        return;
      }
      const REQUEST = {
        type: this.tipoSelected.id,
        year: moment(this.yearInput).format('YYYY'),
        month: moment(this.monthInput).format('MM'),
        medico_id: this.medicoSelected.id,
      };
      try {
        const RESPONSE = await axios.get('clinic/reporte/impuesto/print', {
          params: REQUEST,
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'data:application/vnd.ms-excel' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        const A = document.createElement('a');
        A.href = DOWNLOAD_URL;
        A.download = 'Reporte Impuestos - Ventas Medico Terceros.xlsx';
        document.body.appendChild(A);
        A.click();
        document.body.removeChild(A);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    disableYear(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    disableMonth(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
