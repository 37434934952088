<template>
  <div v-if="isLoading">
    <div class="text-center text-danger custom-screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <h3 class="text-center mb-4">Filtros</h3>
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Fecha:</strong></label>
          <div class="col-sm-5">
            <date-picker
              v-model="fecha"
              lang="es"
              format="DD/MM/YYYY"
              type="date"
              value-type="date"
              width="100%">
            </date-picker>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Sucursal:</strong></label>
          <div class="col-sm-3">
            <multiselect
              v-model="sucursalSelected"
              :options="sucursales"
              :disabled="true"
              label="nombre_comercial" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Sucursal"
              :allow-empty="false"
              :searchable="true">
              <span slot="caret" v-if="!(sucursalSelected===null)"></span>
            </multiselect>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Usuario:</strong></label>
          <div class="col-sm-3">
            <multiselect
              v-model="usuarioSelected"
              :options="usuarios"
              label="nombre" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Usuarios"
              :allow-empty="false"
              :searchable="true">
              <span slot="caret" v-if="!(usuarioSelected===null)"></span>
            </multiselect>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-5 col-form-label text-right"><strong>Moneda:</strong></label>
          <div class="col-sm-3">
            <multiselect
              v-model="monedaSelected"
              :options="monedas"
              label="name" track-by="id"
              select-label="" deselect-label="X"
              placeholder="Moneda"
              :allow-empty="false"
              :show-labels="false">
              <span slot="caret" v-if="!(monedaSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
            </multiselect>
          </div>
        </div>
        <div align="center">
          <button class="btn btn-warning"
           type="button"
           @click="generarInforme">
            Consultar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapGetters } from 'vuex';

export default {
  name: 'MedicoTerceros',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      isLoading: true,
      fecha: new Date(),
      sucursalSelected: null,
      sucursales: [],
      usuarioSelected: null,
      usuarios: [],
      monedaSelected: null,
      monedas: [],
    };
  },
  async created() {
    this.isLoading = true;
    await this.cargarDatosIniciales();
    this.isLoading = false;
  },
  methods: {
    async cargarDatosIniciales() {
      try {
        const RESPONSE = await axios.get('/clinic/reporte');
        this.sucursalSelected = RESPONSE.data.data.sucursal;
        this.usuarioSelected = RESPONSE.data.data.user;
        const { users: USERS } = RESPONSE.data.data;
        if (this.isBloqueadoTodosLosUsuarios) {
          this.usuarios = [this.usuarioSelected];
        } else {
          this.usuarios = USERS;
        }
        this.monedas = RESPONSE.data.data.monedas;
        /* eslint-disable prefer-destructuring */
        this.monedaSelected = this.monedas.find((item) => item.is_principal === 1);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    generarInforme() {
      if (this.fecha === null) {
        util.showNotify('Elija una Fecha', 'warn');
        return;
      }
      if (this.monedaSelected === null) {
        return;
      }
      const data = {
        fecha: moment(this.fecha).format('YYYY-MM-DD'),
        sid: this.sucursalSelected.id,
        uid: this.usuarioSelected.id,
        mid: this.monedaSelected.id,
      };
      const hash = btoa(JSON.stringify(data));
      window.open(`/reportes/medico-terceros/${hash}`);
    },
  },
  computed: {
    ...mapGetters('main', [
      'isBloqueadoTodosLosUsuarios',
    ]),
  },
};
</script>

<style scoped>
.custom-screen-center {
  transform: translateY(16vh);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
